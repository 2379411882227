<template>
    <div class="container_site">
        <div class="ubication_dimension">
            <p class="ubication_dimension--home" @click="$router.push({name: 'Home'})">Inicio</p>
            <img src="@/assets/img/arrow-breadcrumb.svg" class="ubication_dimension--breadcrumb">
            <p class="ubication_dimension--name_dimension">Preguntas frecuentes</p>
        </div>
        <div class="container_faqs">
            <div class="container_faqs__header">
                <div class="container_faqs__header--title">
                    Preguntas frecuentes
                </div>
                <div class="container_faqs__header--text">
                    {{description || 'No hay descripción añadida.'}}
                </div>
            </div>
            <div class="container_faqs__items">
                <p class="text-center" v-if="faqs.length < 1">No se han añadido preguntas frecuentes</p>
                <div class="container_faqs__items--item" v-for="(Faq, keyFaq) in faqs" :key="keyFaq" :style="{'border-bottom': (keyFaq + 1) == faqs.length ? '1px solid transparent': '1px solid #383838'}">
                    <div class="container_faqs__items--link" v-b-toggle="'accordion-'+keyFaq">
                        <div class="container_faqs__items--title" :style="{'color': getClient().primary_color}">
                            {{Faq.title}}
                        </div>
                        <img src="@/assets/img/icono-abrir.svg" v-if="!Faq.toggle">
                        <img src="@/assets/img/icono-cerrar.svg" v-else>
                    </div>
                    <b-collapse :id="'accordion-'+keyFaq" accordion="my-accordion" v-html="Faq.description">
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            faqs: [],
            description: null
        }
    },
    methods: {
        getFaqs(){
            this.$http.get('townhalls/getFAQ/'+this.getClient().id).then(response => {
                this.description = response.body.description;
                if (response.body.faqs != null) {
                    response.body.faqs.forEach(element => {
                        this.faqs.push({
                            title: element.title,
                            description: element.description,
                            toggle: false
                        })
                    });
                }
            }).catch(error => {
                console.log('Error al consultar faqs', error)
            })
        }
    },
    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            this.faqs[collapseId.split('-')[1]].toggle = isJustShown;
        });
    },
    created() {
        this.getFaqs();
    },
}
</script>